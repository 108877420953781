import React, { useState, useEffect } from "react";
import { Icon, Grid, Card, Form, Dimmer } from "components/lynx-components";
import Button from "@mui/material/Button";
import _ from "lodash";
import { validationService } from "./../../../services/validation";
import * as jsonpatch from "fast-json-patch";
import { deleteLookup, patchLookup } from "./../../../services/lookup";
import { roleMatch } from "../../../actions/auth";
import { LookupTypes, UserRoles } from "../../../types/enums";
import { MuiColorInput } from "mui-color-input";
import { defaultMonLocTypeColor } from "./add-lookup-header";
import useAlert from "hooks/useAlert";

const initialForm = {
  id: "",
  lookupType: "",
  code: "",
  description: "",
  codeError: "",
  color: "",
};
export function EditLookupModal(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [formState, setFormState] = useState(initialForm);
  const [initialFormState, setInitialFormState] = useState({});
  const { showAlert } = useAlert();

  const handleColorChange = (color) => {
    setFormState({ ...formState, color: color });
  };
  useEffect(() => {
    if (props.lookup) {
      setFormStateFromProps(props.lookup);
      setIsLoading(false);
    }
  }, [props.lookupId]);

  const setFormStateFromProps = (lookup) => {
    let newState = {
      id: lookup.id,
      lookupType: lookup.lookupType,
      code: lookup.code,
      description: lookup.description,
      color: lookup.color,
    };

    setFormState({
      ...newState,
      color:
        lookup.color == null || lookup.color == ""
          ? defaultMonLocTypeColor
          : lookup.color,
    });
    setInitialFormState(newState);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }

    //unset errors
    let formToSave = validationService.unsetErrors(
      formState,
      "codeError",
      "colorError"
    );

    if (props.lookup.id > 0) {
      var diff = jsonpatch.compare(initialFormState, formToSave);
      var dto = {
        lookupPatch: diff,
      };
      patchLookup(props.lookup.id, dto)
        .then((res) => {
          showAlert("success", props.lookupName + " saved.");
          props.handleLookupSave();
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "code",
      "codeError",
      props.nameLabel
    );
    if (hasColorOption()) {
      validationService.validateRequiredField(
        newState,
        "color",
        "colorError",
        "Color"
      );
    }
    let errorFields = ["codeError"];
    if (hasColorOption()) {
      errorFields = [...errorFields, "colorError"];
    }
    isFormValid = !validationService.hasError(newState, ...errorFields);

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleDelete = () => {
    deleteLookup(props.lookup.id).then((res) => {
      showAlert("success", `${props.lookup.code} deleted.`);
      props.handleLookupSave();
    });
  };

  const hasColorOption = () => {
    return (
      _.toLower(formState.lookupType) ===
      _.toLower(LookupTypes.MonitoringLocationType)
    );
  };

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Dimmer active={isLoading} loader>
          <Card.Title>
            {"Edit " + props.lookupName}

            <Icon
              name="x"
              onClick={props.handleModalClose}
              className="float-right pointer"
            ></Icon>
          </Card.Title>

          <Grid.Row alignItems="center">
            <Grid.Col sm={12}>
              <Form.Group label={props.codeLabel}>
                <Form.Input
                  type="text"
                  value={formState.code}
                  name="code"
                  onChange={handleInputChange}
                  error={formState.codeError}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row alignItems="center">
            <Grid.Col sm={12}>
              <Form.Group label={props.descriptionLabel}>
                <Form.Input
                  type="text"
                  value={formState.description}
                  name="description"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          {hasColorOption() && (
            <Grid.Row alignItems="center">
              <Grid.Col sm={12}>
                <Form.Group label="Color">
                  <MuiColorInput
                    format="hex"
                    variant="standard"
                    value={formState.color}
                    onChange={handleColorChange}
                    error={formState.colorError}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          )}
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        {roleMatch([UserRoles.Admin]) && (
          <>
            <Button
              disabled={
                props.lookup.isInUse && !roleMatch([UserRoles.InternalUser])
              }
              variant="contained"
              color="error"
              className="float-left"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </>
        )}
        <Button variant="contained" className="float-right" onClick={saveForm}>
          Save
        </Button>
        {props.lookup.isInUse && !roleMatch([UserRoles.InternalUser]) && (
          <div
            className="ml-1 text-red"
            style={{
              height: 35,
              verticalAlign: "top",
              display: "table-cell",
              fontSize: 14,
            }}
          >
            <span className="ml-2"> Unable to delete when in use</span>
          </div>
        )}
      </Card.Footer>
    </Form>
  );
}
