import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useState, useEffect } from "react";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { getContactGroups, getContacts } from "./../../../services/contacts";
import { contactColumns as cc } from "./contact-columns";
import { contactGroupColumns as cgc } from "./contact-group-columns";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import queryString from "query-string";
import _ from "lodash";
import { localStorageService } from "../../../services/local-storage-service";
import { ContactGroupModal } from "./contact-group-modal";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
export function ContactList(props) {
  const [gridView, setGridView] = useState("");
  const [contactColumns, setContactColumns] = useState([]);
  const [contactGroupColumns, setContactGroupColumns] = useState([]);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const dimensions = useWindowDimensions();
  const lsKey = "contact_grid_view";

  useEffect(() => {
    prepColumns();
  }, []);
  useEffect(() => {
    const values = queryString.parse(props.location.search);
    if (!values.view) {
      var selectedGridView = localStorageService.getLocalStorage(lsKey);
      if (selectedGridView) {
        setGridView(selectedGridView);
        props.history.push(`/contacts?view=${_.toLower(selectedGridView)}`);
      } else {
        setGridView("contacts");
        props.history.push("/contacts?view=contacts");
      }
    } else {
      setGridView(values.view ? values.view : "contacts");
    }
  }, []);
  const handleEditClick = (id) => {
    handleNavigateTo(null, "/contacts/" + id);
  };

  const prepColumns = () => {
    let contactCols = [...cc];
    let colIndex = contactCols.findIndex(
      (x) => x.field == "numberOfAssociatedEvents"
    );
    contactCols[colIndex].renderCell = (params) => {
      if (params.value > 0)
        return (
          <Link
            underline="hover"
            href="#"
            onClick={(e) => {
              handleNavigateTo(e, `events?contactId=${params.row.id}`);
            }}
          >
            {params.value}
          </Link>
        );
      return params.value;
    };
    setContactColumns(contactCols);

    let contactGroupCols = [...cgc];
    let colIndex2 = contactGroupCols.findIndex(
      (x) => x.field == "numberOfAssociatedEvents"
    );
    contactGroupCols[colIndex2].renderCell = (params) => {
      if (params.value > 0)
        return (
          <Link
            underline="hover"
            href="#"
            onClick={(e) => {
              handleNavigateTo(e, `events?contactGroupId=${params.row.id}`);
            }}
          >
            {params.value}
          </Link>
        );
      return params.value;
    };
    setContactGroupColumns(contactGroupCols);
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    props.history.push(url);
  };
  const handleGridViewChange = (e) => {
    setGridView(e.target.value);
    localStorageService.setLocalStorage(lsKey, e.target.value);
    props.history.push(`/contacts?view=${_.toLower(e.target.value)}`);
  };
  const getContactListHeader = () => {
    return (
      <>
        <Typography
          variant="h3"
          component="div"
          className="align-self-center mr-5"
        >
          Contacts
        </Typography>

        <span className=" align-self-end grid-view-select">
          <FormControl
            variant="standard"
            sx={
              dimensions.isMobile
                ? {
                    minWidth: 250,
                  }
                : {
                    marginBottom: 1,
                    marginTop: 1,
                    minWidth: 250,
                  }
            }
          >
            <InputLabel id="demo-simple-select-label">
              Select Grid View
            </InputLabel>
            <Select
              value={gridView}
              onChange={handleGridViewChange}
              className="font-weight-bold"
            >
              <MenuItem value={"contacts"} key={"contacts"}>
                {"All Contacts"}
              </MenuItem>

              <MenuItem value={"contact-groups"} key={"contact-groups"}>
                {"Contact Groups"}
              </MenuItem>
            </Select>
          </FormControl>
        </span>
      </>
    );
  };

  const canAddEdit = roleMatch([
    UserRoles.Admin,
    UserRoles.SuperAdmin,
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
  ]);

  return (
    <>
      {gridView == "contacts" && (
        <LynxDataGrid
          columns={contactColumns}
          hasEditButton={canAddEdit}
          getDataFunction={getContacts}
          localStorageName="contacts"
          handleEditClick={canAddEdit ? handleEditClick : undefined}
          entityName={"Contact"}
          header={getContactListHeader}
          addButtonAction={
            canAddEdit
              ? () => {
                  props.history.push(`/add-contact`);
                }
              : undefined
          }
          {...props}
        />
      )}
      {gridView == "contact-groups" && (
        <>
          <LynxDataGrid
            columns={contactGroupColumns}
            hasEditButton={canAddEdit}
            isLoading={showAddGroupModal}
            hasNoLookups
            addButtonText="Add Contact Group"
            getDataFunction={getContactGroups}
            localStorageName="contactgroups"
            handleEditClick={(id) => {
              if (canAddEdit) {
                setSelectedId(id);
                setShowAddGroupModal(true);
              }
            }}
            addButtonAction={
              canAddEdit
                ? () => {
                    setShowAddGroupModal(true);
                  }
                : undefined
            }
            entityName={"ContactGroup"}
            header={getContactListHeader}
            {...props}
          />
        </>
      )}
      {showAddGroupModal && (
        <ContactGroupModal
          open={showAddGroupModal}
          contactGroupId={selectedId}
          handleClose={() => {
            setShowAddGroupModal(false);
            setSelectedId(null);
          }}
          handleGroupFormSave={() => {
            setShowAddGroupModal(false);
            setSelectedId(null);
          }}
        />
      )}
    </>
  );
}
