import React, { useState } from "react";

import Button from "@mui/material/Button";
import _ from "lodash";
import { validationService } from "../../../../services";
import { Grid, Card, Form, Icon } from "components/lynx-components";
import SingleSelect from "components/form-controls/single-select";
import {
  useDeleteUnitConversionMutation,
  useUpdateUnitConversionMutation,
} from "services/rtkApi/endpoints/unitConversion";
import { LynxDialog } from "components/lynx-dialog";
import useAlert from "hooks/useAlert";

export function EditUnitConversion(props) {
  const [formState, setFormState] = useState(props.unitConversion);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [updateTrigger] = useUpdateUnitConversionMutation();

  const [deleteTrigger, deleteData] = useDeleteUnitConversionMutation();
  const { showAlert } = useAlert();
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;

    _.set(newState, name, value);

    setFormState(newState);
  };

  const saveForm = () => {
    let formToSave = validationService.unsetErrors(
      formState,
      ...["fromUnitIdError", "toUnitIdError", "conversionFactorError"]
    );
    //validate here
    if (!validateDataForSave()) {
      return;
    }

    updateTrigger(formToSave).then((res) => {
      if (res.data) {
        showAlert("success", `Unit conversion updated.`);
      }

      if (res.error) {
        showAlert("error", res.error?.data?.message);
      }
    });
  };

  const handleDelete = () => {
    deleteTrigger(props.unitConversion.id).then((res) => {
      showAlert("success", `Unit conversion deleted.`);
      props.handleModalClose();
    });
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "fromUnitId",
      "fromUnitIdError",
      "From Unit"
    );

    validationService.validateRequiredField(
      newState,
      "toUnitId",
      "toUnitIdError",
      "To Unit"
    );

    validationService.validateRequiredField(
      newState,
      "conversionFactor",
      "conversionFactorError",
      "Conversion Factor"
    );
    isFormValid = !validationService.hasError(
      newState,
      ...["fromUnitIdError", "toUnitIdError", "conversionFactorError"]
    );

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Card.Title>
          Edit Unit Conversion
          <Icon
            name="x"
            onClick={props.handleModalClose}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Grid.Row>
          <Grid.Col md={6} width={12}>
            <Form.Group label="From Unit">
              <SingleSelect
                disabled={true}
                onChange={handleInputChange}
                dropdownValues={props.unitList}
                label="code"
                id="id"
                name="fromUnitId"
                value={formState.fromUnitId}
                error={formState.fromUnitIdError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={6} width={12}>
            <Form.Group label="To Unit">
              <SingleSelect
                disabled={true}
                onChange={handleInputChange}
                dropdownValues={props.unitList}
                label="code"
                id="id"
                name="toUnitId"
                value={formState.toUnitId}
                error={formState.toUnitIdError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group label={"Conversion Factor"} isRequired>
              <Form.Input
                type="text"
                value={formState.conversionFactor}
                name="conversionFactor"
                onChange={handleInputChange}
                error={formState.conversionFactorError}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
      <Card.Footer>
        <Button
          variant="contained"
          color="error"
          className="float-left"
          onClick={() => {
            setIsOpenDelete(true);
          }}
        >
          Delete
        </Button>
        <Button variant="contained" className="float-right" onClick={saveForm}>
          Save
        </Button>
      </Card.Footer>

      <LynxDialog
        open={isOpenDelete}
        title={"Delete Confirmation"}
        description={
          deleteData.isLoading
            ? "Deleting data. Do not close the window."
            : "Are you sure you want to remove the unit conversion?"
        }
        handleConfirm={handleDelete}
        handleClose={() => {
          setIsOpenDelete(false);
        }}
      />
    </Form>
  );
}
