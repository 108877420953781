import { GridColDef } from "@mui/x-data-grid-pro";

export interface RootState {
  organization: OrganizationState;
  account: AccountState;
  lookups: LookupState;
  auth: AuthState;
}

interface OrganizationState extends OrganizationDto {
  isLoading: boolean;
}

interface AccountState extends UserDto {
  isLoading: boolean;
}

export interface OrganizationDto {
  id?: number;
  name?: string;
  customerId?: number;
  hasPhotoAnalysis?: boolean;
  hasPermitFeature?: boolean;
  hasTimeSeries?: boolean;
  lockInspectionOnSubmit?: boolean;
  eventPermitLabelFunction?: string;
  hasEvents?: boolean;
  hasMonitoring?: boolean;
  hasIncidents?: boolean;
  hasInspections?: boolean;
  hasEnergyLog?: boolean;
  hasCustomerPortal?: boolean;
  customerName?: string;
  organizationUsers?: OrganizationUserDto[];
  featureFlags?: { [key: string]: boolean };
  licenseExpirationDateTimeUtc?: string;
  customer?: CustomerDto;
}

export interface LookupState {
  users?: UserDto[];
}

export interface AuthState {
  isFetching: boolean;
  isAuthenticated: boolean;
  requiresOrganizationSelection: boolean;
  organizationUsers: OrganizationUserDto[];
  user: UserLoginState;
}

export interface UserLoginState {
  email?: string;
  password?: string;
}

export interface OrganizationUserDto {
  id?: number;
  userId?: number;
  organizationId?: number;
  organizationName?: string;
}

export interface CustomerDto {
  id?: number;
  name?: string;
  userCount?: number;
  incidentsLicenseCount?: number;
  monitoringLicenseCount?: number;
  eventsLicenseCount?: number;
  inspectionsLicenseCount?: number;
  energyLogsLicenseCount?: number;
  licenseExpirationDateTimeUtc?: string | null;
  isWyloo?: boolean;
  isDeleted?: boolean;
  users?: UserDto[];
  numberOfUsers?: number;
  numberOfOrgs?: number;
  organizations?: OrganizationDto[];
}

export interface UserDto {
  id?: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  title?: string;
  email?: string;
  isInternal?: boolean;
  role?: string;
  isIncidentsUser?: boolean;
  isMonitoringUser?: boolean;
  isEventsUser?: boolean;
  isEnergyUser?: boolean;
  isEnergyReviewer?: boolean;
  isInspectionsUser?: boolean;
  isTimeSeriesUser?: boolean;
  isActive?: boolean;
  hasAcceptedTermsOfUse?: boolean;
  timezoneId?: string;
  isAdmin?: boolean;
  eventRole?: string;
  userRoles?: string[];
  isPortalUser?: boolean;
  organizationName?: string;
  language?: string;
  newPassword?: string;
  coordinateType?: string;
  organizationType?: string;
  organizationId?: number;
  registrationGuid?: string;
  notes?: string;
  customerPortalName?: string;
  registrationCompletedDateTimeUtc?: string;
  initialTourCompletedDateTimeUtc?: string;
  eventsGridTourCompletedDateTimeUtc?: string;
  monitoringGridTourCompletedDateTimeUtc?: string;
  incidentsGridTourCompletedDateTimeUtc?: string;
  customerPortalUrl?: string;
  hasEventConfidentialAccess?: boolean;
}

export interface ActionUserDto {
  id?: number;
  actionId?: number;
  userId?: number;
  userFullName?: string;
  type?: string;
}

export interface ActionDto {
  id?: number;
  organizationId?: number;
  actionNumber?: string;
  linkedEventId?: number;
  linkedIncidentId?: number;
  assetId?: number;
  assetName?: string;
  title?: string;
  description?: string;
  priority?: string;
  categoryId?: number;
  category?: string;
  assignedToUserId?: number;
  assignedToUserFullName?: string;
  createdByUserId?: number;
  createdByUserFullName?: string;
  completedByUserFullName?: string;
  dueInDays?: number;
  dueDate?: string;
  closedDateTime?: string;
  completedDateTime?: string;
  closedByUserId?: number;
  closedByUserFullName?: string;
  evidenceRequired?: string;
  approvedByUserId?: number;
  evidenceRequiredId?: number;
  approvedByUserFullName?: string;
  approvedDateTime?: string;
  verifierUserId?: number;
  verifierUserFullName?: string;
  verifiedDateTime?: string;
  latitude?: number;
  longitude?: number;
  createdDateTimeUtc?: string;
  lastModifiedByUserId?: number;
  lastModifiedByUserFullName?: string;
  lastModifiedDateTimeUtc?: string;
  status?: string;
  completedComment?: string;
  isClosedOverride?: boolean;
  verifiedComment?: string;
  eventNumber?: string;
  eventCategory?: string;
  eventCategoryId?: number;
  incidentTypeId?: number;
  linkedEvent?: EventDto;
  linkedIncident?: IncidentDto;
  sourceNumber?: string;
  assetNames?: string;
  actionUsers?: ActionUserDto[];
}
export interface IncidentDto {
  id?: number;
  assetId?: number;
  assetName?: string;
  incidentDateTime?: string;
  expectedTypeId?: number;
  expectedType?: string;
  expectedSubTypeId?: number;
  expectedSubType?: string;
  expectedSeverityId?: number;
  expectedSeverity?: string;
  title?: string;
  description?: string;
  immediateActions?: string;
  contributingFactors?: string;
  latitude?: number;
  longitude?: number;
  locationDetails?: string;
  submittedByUserId?: number;
  submittedByUserFullName?: string;
  submittedDateTimeUtc?: string;
  incidentNumber?: string;
  severityId?: number;
  severity?: string;
  typeId?: number;
  type?: string;
  subTypeId?: number;
  subType?: string;
  investigationRequired?: boolean;
  investigationLeadUserId?: number;
  investigationLeadUserFullName?: string;
  initialReviewComment?: string;
  initialReviewCompletedDateTimeUtc?: string;
  initialReviewCompletedByUserId?: number;
  initialReviewCompletedByUserFullName?: string;
  investigationSummary?: string;
  investigationSubmittedDateTimeUtc?: string;
  investigationSubmittedByUserId?: number;
  investigationSubmittedByUserFullName?: string;
  finalReviewerUserId?: number;
  finalReviewerUserFullName?: string;
  finalReviewComment?: string;
  finalReviewCompletedDateTimeUtc?: string;
  finalReviewCompletedByUserId?: number;
  finalReviewCompletedByUserFullName?: string;
  createdByUserId?: number;
  createdByUserFullName?: string;
  createdDateTimeUtc?: string;
  lastModifiedByUserId?: number;
  lastModifiedByUserFullName?: string;
  lastModifiedDateTimeUtc?: string;
  userWitnesses?: number[];
  userParticipants?: number[];
  status?: string;
  investigationInspectionFormId?: number;
  affectedPartiesContactTypes?: string;
  expectedDepartmentId?: number;
  departmentId?: number;
  departmentName?: string;
  incidentUsers?: IncidentUserDto[];
  investigationInspectionFormFieldValues?: InspectionFormFieldValueDto[];
  incidentParticipants?: IncidentParticipantDto[];
  incidentAffectedParties?: IncidentParticipantDto[];
  entityAttachments?: EntityAttachmentDto[];
}

export interface IncidentUserDto {
  id?: number;
  incidentId?: number;
  userId?: number;
  userFullName?: string;
  type?: string;
}

export interface LookupDto {
  id?: number;
  lookupType?: string;
  code?: string;
  description?: string;
  parentLookupCode?: string;
  parentLookupId?: number;
  childLookupCode?: string;
  childLookupId?: number;
  isSystem?: boolean;
  complianceStandardsList?: LookupDto[];
  isInUse?: boolean;
  isRestricted?: boolean;
  isReviewable?: boolean;
  requireChildSelection?: boolean;
  color?: string;
}

export interface IncidentParticipantDto {
  id?: number;
  newId?: string;
  userId?: number;
  isWitness?: boolean;
  participantTypeId?: number;
  participantType?: string;
  name?: string;
  details?: string;
  inspectionFormFieldValues?: InspectionFormFieldValueDto[];
}

export interface InspectionFormFieldValueDto {
  id?: number;
  inspectionFormFieldId?: number;
  inspectionFormFieldName?: string;
  inspectionFormFieldType?: string;
  incidentId?: number;
  textValue?: string;
  numericValue?: number;
  dateTimeValue?: string;
  dateValue?: string;
  booleanValue?: boolean;
  signatureValue?: string;
}
export interface InspectionFormDto {
  id?: number;
  name?: string;
  description?: string;
  title?: string;
  formType?: string;
  retiredDate?: string | null;
  isRetired?: boolean | null;
  inspectionFormFields?: InspectionFormFieldDto[];
  inspectionFormLookups?: InspectionFormLookupDto[];
  isInUse?: boolean | null;
  isEquipmentInspection?: boolean;
}

export interface InspectionFormFieldDto {
  id?: number;
  inspectionFormId?: number;
  name?: string;
  label?: string;
  type?: string;
  isRequired?: boolean;
  dropdownValues?: string;
  width?: number;
  position?: number;
}

export interface InspectionFormLookupDto {
  lookupId?: number;
  secondaryLookupId?: number | null;
}
export interface EntityAttachmentDto {
  entityAttachmentId?: number;
  entityType?: number;
  entityId?: number;
  fileLocation?: number;
  fileType?: number;
  fileSize?: number;
  contentType?: string;
  fileName?: string;
  filePath?: string;
  thumbnailPath?: string;
  createdByUserId?: number;
  createdDateTimeUtc?: number;
  createdByUserFullName?: string;
  data?: any[];
  incidentInvestigationId?: number;
  incidentId?: number;
  description?: string;
  thumbnail?: Blob | MediaSource;
  isImport?: boolean;
  importHistoryId?: number;
  importDateTimeUtc?: string;
  uploaderUserFullName?: string;
}

export interface LynxGridColDef extends GridColDef {
  lookup?: string;
  lookupValues?: any[];
  valueField?: string;
  labelField?: string;
  importable?: boolean;
  updateable?: boolean;
  nullable?: boolean;
  dtoId?: string;
  disabled?: boolean;
  useInDashboard?: boolean;
}

export interface EntityReviewDto {
  eventId?: number | null;
  reviewedByUserId?: number;
  reviewByUserEmail?: string;
  reviewByUserFullName?: string;
  reviewDateTimeUtc?: string;
  reviewCompleted?: boolean;
  comments?: string;
}

export interface AssetDto {
  id?: number;
  name?: string;
  assetNumber?: string;
  assetType?: string;
  assetTypeId?: number;
  organizationId?: number;
  oganizationName?: string;
  crimeRisk?: string;
  terrorismRisk?: string;
  unrestRisk?: string;
  environmentalRisk?: string;
  politicalRisk?: string;
  economicRisk?: string;
  socialRisk?: string;
  geojson?: string;
  latitude?: number;
  longitude?: number;
  description?: string;
  centroid?: number[];
  harvestAssetId?: number;
  status?: string;
  isHarvestEnabled?: boolean;
  centroidLatitude?: number;
  centroidLongitude?: number;
}
export interface AssetLayerDto {
  name?: string;
  type?: string;
  geojson?: string;
  centroid?: number[];
}

export interface EventGridDto extends ICustomFields {
  id?: number;
  organizationId?: number;
  eventDueDateTime?: string;
  eventDateTime?: string;
  assignedToUserId?: number;
  assignedToUserFullNames?: string;
  eventNumber?: string;
  eventCategory?: string;
  eventCategoryId?: number;
  eventSubCategory?: string;
  eventSubCategoryId?: number;
  description?: string;
  assetId?: number;
  assetName?: string;
  assetNames?: string;
  permitNames?: string;
  followUpRequired?: boolean;
  followUpDate?: string;
  followUpDescription?: string;
  customerName?: string;
  latitude?: number;
  longitude?: number;
  utmNumber?: number;
  utmLetter?: string;
  utmEasting?: number;
  utmNorthing?: number;
  geojson?: string;
  createdByFullName?: string;
  lastModifiedByFullName?: string;
  urgency?: string;
  eventCustomFieldValues?: EventCustomFieldValueDto[];
  createdDateTimeUtc?: string;
  createdByUserId?: number;
  lastModifiedDateTimeUtc?: string;
  numberOfAttachments?: number;
  co2Emissions?: number;
  lastReviewedDateTimeUtc?: string;
  reviewCompleted?: boolean;
  lastReviewedBy?: string;
  lastReviewedComments?: string;
  parentEventId?: number;
  isParent?: boolean;
  tagGroup?: string;
  tagCategory?: string;
  tag?: string;
  isReviewable?: boolean;
  status?: string;
  appliesToPermit?: boolean;
  isConfidential?: boolean;
}

export interface EventCustomFieldValueDto {
  id?: number;
  eventCustomFieldId?: number;
  fieldType?: string;
  eventId?: number;
  textValue?: string;
  numericValue?: number;
  dateTimeValue?: string;
  dateValue?: string;
  booleanValue?: boolean;
  isDeleted?: boolean;
  fieldName?: string;
  fieldLabel?: string;
  position?: number;
  dateTimeLocal?: string;
}

export interface ICustomFields {
  eventCustomFieldValues?: EventCustomFieldValueDto[];
}

export enum TimeUnit {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
  custom = "custom",
}

interface CustomDateDateOnly {
  value?: string;
}

export interface EventScheduleDto {
  assets?: AssetDto[];
  eventCategoryId?: number;
  eventSubCategoryId?: number;
  startDate?: string;
  repeatEveryCount?: number;
  repeatEveryGranularity?: string;
  endDate?: string;
  daysOfWeek?: string[];
  customDates?: CustomDateDateOnly[];
  description?: string;
  entityUsers?: EntityUserDto[];
}

export interface EntityUserDto {
  id?: number;
  userId?: number;
  eventId?: number;
  fullName?: string;
}

export enum EventContactTypes {
  Contact = "Contact",
  ContactGroup = "ContactGroup",
}

export interface EventContactDto {
  id?: number;
  type?: EventContactTypes;
  name?: string;
  contactGroupName?: string;
  entityContactId?: number;
}

export interface CustomFieldState {
  numericValue?: number;
  textValue?: string;
  booleanValue?: boolean;
  dateValue?: string;
  dateTimeValue?: string;
}

export interface EventCustomFieldDto {
  id?: number;
  parentLookupId?: number;
  parentLookupCode?: string;
  childLookupId?: number;
  childLookupCode?: string;
  fieldName?: string;
  fieldLabel?: string;
  fieldDescription?: string;
  fieldType?: string;
  isRequired?: boolean;
  incidentStage?: string;
  isEmissionsField?: boolean;
  emissionsScope?: string;
  co2Factor?: number;
  entityType?: string;
  isDeleted?: boolean;
  position?: number;
  dropdownValues?: string;
}

export interface EventDto extends EventGridDto {
  tagIds?: number[];
  eventContacts?: EventContactDto[];
  eventTags?: EntityLookupDto[];
  tags?: string[];
  isOffline?: boolean;
  linkedEvents?: EventDto[];
  pendingEmailIntegrationReview?: boolean;
  isConfidential?: boolean;
  actions?: ActionDto[];
  importRowNumber?: number;
  importHistoryId?: number;
  importHistory?: ImportHistoryDto;
  associatedContacts?: EntityContactDto[];
  associatedContactGroups?: EntityContactDto[];
  comments?: EntityCommentDto[];
  entityAssets?: EntityAssetDto[];
  entityPermits?: EntityPermitDto[];
  entityUsers?: EntityUserDto[];
  assignedToUserFullName?: string;
  customFieldData?: any;
}

export interface EntityLookupDto {
  id?: number;
  eventId?: number;
  lookupCode?: string;
  lookupId?: number;
  lookupDescription?: string;
  parentLookupCode?: string;
  parentLookupDescription?: string;
  childLookupCode?: string;
  childLookupDescription?: string;
}

export interface ImportHistoryDto {
  id?: number;
  importSpecificationId?: number;
  importSpecificationName?: string;
  importDateTimeUtc?: string;
  fileName?: string;
  importStatus?: string;
  uploaderUserId?: number;
  uploaderUserFullName?: string;
  numberOfTotalRows?: number;
  numberOfSuccessRows?: number;
  numberOfErrorRows?: number;
  numberOfVarianceExceedances?: number;
  entityType?: string;
  filePath?: string;
  timeseriesMonitoringLocationId?: number;
  timeseriesMinDate?: string;
  timeseriesMaxDate?: string;
}

export interface EntityContactDto {
  entityContactId?: number;
  eventId?: number;
  contactId?: number;
  contactGroupId?: number;
  contact?: ContactDto;
  contactGroup?: ContactGroupDto;
}

export interface EntityCommentDto {
  id?: number;
  comment?: string;
  plainTextComment?: string;
  eventId?: number;
  createdByUserId?: number;
  createdByUserFullName?: string;
  createdDateTimeUtc?: string;
  lastModifiedDateTimeUtc?: string;
  createdDateTimeLocal?: string;
}

export interface EntityAssetDto {
  id?: number;
  assetId?: number;
  assetName?: string;
  permitId?: number;
  eventId?: number;
}

export interface EntityPermitDto {
  id?: number;
  permitId?: number;
  permitNumber?: string;
  eventId?: number;
}

export interface ContactDto {
  id?: number;
  fullName?: string;
  phone?: string;
  email?: string;
  title?: string;
  notes?: string;
  fullAddress?: string;
  latitude?: number;
  longitude?: number;
  contactGroupId?: number;
  contactGroupName?: string;
  isInternal?: boolean;
  numberOfAssociatedEvents?: number;
}

export interface ContactGroupDto {
  id?: number;
  name?: string;
  description?: string;
  contactGroupType?: string;
  contactGroupTypeId?: number;
  numberOfAssociatedEvents?: number;
  numberOfAssociatedContacts?: number;
}

export interface EventPatchDto {
  eventCustomFieldValues?: EventCustomFieldValueDto[];
  eventPatch?: EventDto;
}

export interface ReportDto {
  id?: number;
  name?: string;
  description?: string;
  entityType?: string;
}

export interface CorrespondenceLogDto {
  id?: number;
  eventId?: number;
  correspondenceTypeId?: number;
  correspondenceTypeCode?: string;
  correspondenceDateTimeUtc?: Date;
  subject?: string;
  details?: string;
  fromDetails?: string;
  toDetails?: string;
  emailIntegrationLogId?: number;
  createdByUserFullName?: string;
  createdDateTimeUtc?: Date;
  lastModifiedByUserFullName?: string;
  lastModifiedDateTimeUtc?: Date;
  correspondenceLogContacts?: CorrespondenceLogContactDto[];
  emailIntegrationLog?: EmailIntegrationLogDto;
}

export interface CorrespondenceLogContactDto {
  id?: number;
  correspondenceLogId?: number;
  userId?: number;
  userFullName?: string;
  contactId?: number;
  contactFullName?: string;
  contactGroupId?: number;
  contactGroupName?: string;
  isFrom?: boolean;
  isTo?: boolean;
}
export interface EmailIntegrationLogDto {
  id?: number;
  conversationId?: string;
  internetMessageId?: string;
  sentDateTime?: Date;
  createdDateTime?: Date;
  recievedDateTime?: Date;
  subject?: string;
  body?: string;
  from?: string;
  toRecipients?: string[];
  status?: string;
}
export interface EventReportDto extends EventDto {}
export interface PermitDto {
  id?: number;
  permitNumber?: string;
  permitTypeId?: number;
  permitTypeName?: string;
  assetNames?: string;
  permitType?: LookupDto;
  startDate?: string | null;
  expiryDate?: string | null;
  appliedDate?: string | null;
  circulatedDate?: string | null;
  createdByFullName?: string;
  applyToCirc?: number | null;
  applyToAccept?: number | null;
  circToAccept?: number | null;
  entityAssets?: EntityAssetDto[];
  permitFormFields?: EventCustomFieldValueDto[];
  eventCustomFieldValues?: EventCustomFieldValueDto[];
}
