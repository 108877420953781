import Button from "@mui/material/Button";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Card, Form, Grid, Icon } from "components/lynx-components";
import { ListItemText, MenuItem } from "@mui/material";
import { loadAccount } from "actions/account";
import { MultiSelect } from "components/form-controls/multi-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartmentByUserId,
  getDepartments,
} from "services/department-service";
import { useGetEventRolesQuery } from "services/rtkApi/endpoints/roles";
import { validationService } from "../../../services";
import { getUsersLookups, patchUser } from "./../../../services/users";
import { LynxDialog } from "./../../lynx-dialog";
import { setUsers } from "reducers/lookups";
import { getUserId } from "actions/auth";
import useAlert from "hooks/useAlert";

export function UserModal(props) {
  const [formState, setFormState] = useState({});
  const [departmentUsers, setDepartmentUsers] = useState();
  const [initialFormState, setInitialFormState] = useState({});
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  var organization = useSelector((state) => state.organization);
  const eventRolesQuery = useGetEventRolesQuery();

  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  useEffect(() => {
    if (!organization.isLoading && !_.isEmpty(props.user)) {
      var orgsToSet = organization.customer.organizations.filter((org) => {
        return props.user.organizationUsers
          .map((a) => a.organizationId)
          .includes(org.id);
      });
      setOrganizations(orgsToSet);
    }
  }, [organization, props.user]);
  useEffect(() => {
    if (!_.isEmpty(props.user)) {
      setFormStateFromProps(props.user);
      getDepartments().then((res) => {
        setDepartments(
          res.data.map((m) => ({
            departmentId: m.id,
            name: m.name,
            isDepartmentHead: false,
            userId: props.user.id,
          }))
        );
      });
    }
  }, [props.user]);

  useEffect(() => {
    if (departments.length > 0) {
      //the user's department may have changed in same place.
      //call api to get latest value of userdepartment
      getDepartmentByUserId(props.user.id).then((res) => {
        const deptUsers = res.data.map((m) => {
          const dept = departments.find(
            (f) => f.departmentId == m.departmentId
          );
          return {
            departmentId: dept.departmentId,
            name: dept.name,
            isDepartmentHead: m.isDepartmentHead,
            userId: props.user.id,
          };
        });
        setDepartmentUsers(deptUsers);
      });
    }
  }, [departments]);

  useEffect(() => {
    if (eventRolesQuery.data) {
      const currentRole = eventRolesQuery.data.filter((role) => {
        return props.user.userRoles.some((f) => f == role);
      });

      setFormState((prev) => ({ ...prev, eventRole: currentRole[0] }));
    }
  }, [eventRolesQuery.data]);

  const handleDepartmentChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (
        duplicateRemoved.findIndex(
          (o) => o.departmentId === item.departmentId
        ) == -1
      ) {
        duplicateRemoved.push(item);
      } else {
        duplicateRemoved = duplicateRemoved.filter(
          (x) => x.departmentId !== item.departmentId
        );
      }
    });

    setDepartmentUsers(duplicateRemoved);
  };

  const handleRightCheckboxChange = (e, metadata) => {
    const newDeptUsers = [...departmentUsers];
    const index = newDeptUsers.findIndex(
      (f) => f.departmentId == metadata.departmentId
    );

    if (index != -1) {
      newDeptUsers[index].isDepartmentHead = e.target.checked;

      setDepartmentUsers(newDeptUsers);
    }
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value =
      name === "isActive" ||
      name === "isEventsUser" ||
      name === "isMonitoringUser" ||
      name === "isIncidentsUser" ||
      name === "isEnergyUser" ||
      name === "isInspectionsUser" ||
      name === "isEnergyReviewer" ||
      name === "isTimeSeriesUser" ||
      name === "hasEventConfidentialAccess"
        ? e.target.checked
        : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const setFormStateFromProps = (user) => {
    let newState = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      isAdmin: user.isAdmin,
      isActive: user.isActive,
      isEventsUser: user.isEventsUser,
      isIncidentsUser: user.isIncidentsUser,
      isMonitoringUser: user.isMonitoringUser,
      isEnergyUser: user.isEnergyUser,
      isEnergyReviewer: user.isEnergyReviewer,
      registrationCompletedDateTimeUtc: user.registrationCompletedDateTimeUtc,
      isInspectionsUser: user.isInspectionsUser,
      isTimeSeriesUser: user.isTimeSeriesUser,
      hasEventConfidentialAccess: user.hasEventConfidentialAccess,
      customerId: user.customerId,
    };

    setFormState((prev) => ({ ...prev, ...newState }));
    setInitialFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(
      formState,
      "firstNameError",
      "lastNameError"
    );

    //unset errors
    var dto = {
      ...formToSave,
      departmentUsers: departmentUsers,
      organizations: organizations,
    };

    patchUser(props.user.id, dto).then((res) => {
      setShowSaveDialog(true);
      if (props.user.id == getUserId()) {
        dispatch(loadAccount());
      }
      getUsersLookups().then((res) => {
        dispatch(setUsers(res.data));
      });
    });
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "firstName",
      "firstNameError",
      "First name"
    );
    validationService.validateRequiredField(
      newState,
      "lastName",
      "lastNameError",
      "Last name"
    );

    isFormValid = !validationService.hasError(
      newState,
      "firstNameError",
      "lastNameError"
    );

    if (formState.isEventsUser && !formState.eventRole) {
      isFormValid = false;
      newState.eventRoleError = "Event role is required";
    } else {
      newState.eventRoleError = "";
    }

    if (_.isEmpty(organizations)) {
      newState.organizationsError = "Organizations are required.";
      isFormValid = false;
    } else {
      newState.organizationsError = "";
    }

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const roles = useMemo(() => {
    if (eventRolesQuery.data) {
      return eventRolesQuery.data.map((m, i) => ({
        id: m,
        role: m.replace("Events", ""),
      }));
    } else {
      return [];
    }
  }, [eventRolesQuery.data]);

  return (
    <Form className="card dsmodal-main lynx-modal-80">
      <Card.Body>
        <Card.Title>
          Edit User
          <Icon
            name="x"
            onClick={() => props.handleClose(false)}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Grid.Row>
          <>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Email" isRequired>
                <Form.Input
                  disabled
                  name="email"
                  onChange={handleInputChange}
                  value={formState.email}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="First name" isRequired>
                <Form.Input
                  name="firstName"
                  error={formState.firstNameError}
                  onChange={handleInputChange}
                  value={formState.firstName}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Last name" isRequired>
                <Form.Input
                  name="lastName"
                  error={formState.lastNameError}
                  onChange={handleInputChange}
                  value={formState.lastName}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group isRequired label="Role">
                <Form.Select
                  name="isAdmin"
                  value={formState.isAdmin}
                  onChange={handleInputChange}
                >
                  <option value={false}>Regular user</option>
                  <option value={true}>Admin user</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group isRequired label="Organizations">
                <MultiSelect
                  name="organizations"
                  onChange={(e) => {
                    setOrganizations(e.target.value);
                  }}
                  value={organizations}
                  dropdownValues={organization.customer.organizations}
                  key="id"
                  label="name"
                  id="selectedCustomers"
                  error={formState.organizationsError}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Department">
                <MultiSelect
                  name="departmentUsers"
                  onChange={handleDepartmentChange}
                  value={departmentUsers || []}
                  dropdownValues={departments || []}
                  label="name"
                  keyProperty={"departmentId"}
                  hasRightCheckbox
                  header={
                    <MenuItem disabled sx={{ opacity: "1 !important" }}>
                      <ListItemText sx={{ px: 1 }}>Users</ListItemText>
                      <ListItemText sx={{ textAlign: "right" }}>
                        Department Head
                      </ListItemText>
                    </MenuItem>
                  }
                  onChangeRightCheckbox={handleRightCheckboxChange}
                />
              </Form.Group>
            </Grid.Col>
            {!formState.registrationCompletedDateTimeUtc && (
              <Grid.Col>
                <div className="text-red mb-2">
                  User is pending registration
                </div>
              </Grid.Col>
            )}
            <Grid.Col md={12} width={12}>
              <Form.Group>
                <Form.Checkbox
                  label="Active"
                  name="isActive"
                  onChange={handleInputChange}
                  checked={formState.isActive}
                />
              </Form.Group>
            </Grid.Col>

            {organization.customer.energyLogsLicenseCount > 0 && (
              <>
                <Grid.Col md={12} width={12}>
                  <Form.Group>
                    <Form.Checkbox
                      label="Has Access To Energy Module?"
                      name="isEnergyUser"
                      onChange={handleInputChange}
                      checked={formState.isEnergyUser}
                    />
                  </Form.Group>
                </Grid.Col>
                {formState.isEnergyUser && (
                  <Grid.Col md={12} width={12}>
                    <Form.Group className="ml-1">
                      <Form.Checkbox
                        label="Can Perform Final Review of Energy Logs?"
                        name="isEnergyReviewer"
                        onChange={handleInputChange}
                        checked={formState.isEnergyReviewer}
                      />
                    </Form.Group>
                  </Grid.Col>
                )}
              </>
            )}
            {organization.customer.eventsLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Events Module?"
                    name="isEventsUser"
                    onChange={handleInputChange}
                    checked={formState.isEventsUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {formState.isEventsUser && (
              <Grid.Col md={12} width={12}>
                <Form.Group label="Event Permissions">
                  <Card>
                    <Card.Body className="p-3">
                      <Form.Group>
                        <Form.Checkbox
                          label="Confidential Access?"
                          name="hasEventConfidentialAccess"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          checked={formState.hasEventConfidentialAccess}
                        />
                      </Form.Group>
                      <Form.Group label="Event Role" isRequired>
                        <Form.Select
                          name="eventRole"
                          value={formState.eventRole}
                          onChange={handleInputChange}
                          error={formState.eventRoleError}
                        >
                          <option value={""}></option>
                          {roles.map((role) => (
                            <option value={role.id}>{role.role}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Form.Group>
              </Grid.Col>
            )}

            {organization.customer.incidentsLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Incidents Module?"
                    name="isIncidentsUser"
                    onChange={handleInputChange}
                    checked={formState.isIncidentsUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {organization.customer.inspectionsLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Inspections Module?"
                    name="isInspectionsUser"
                    onChange={handleInputChange}
                    checked={formState.isInspectionsUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}

            {organization.customer.monitoringLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Monitoring Module?"
                    name="isMonitoringUser"
                    onChange={handleInputChange}
                    checked={formState.isMonitoringUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {organization.hasTimeSeries > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Time Series Module?"
                    name="isTimeSeriesUser"
                    onChange={handleInputChange}
                    checked={formState.isTimeSeriesUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
          </>
        </Grid.Row>
      </Card.Body>
      <Card.Footer>
        <div>
          <Button
            variant="contained"
            className="float-right"
            onClick={saveForm}
          >
            Save user
          </Button>
        </div>
      </Card.Footer>
      {showSaveDialog && (
        <LynxDialog
          open={showSaveDialog}
          handleConfirm={() => props.handleClose(true)}
          title={`User Updated`}
          description={`The user must log out and log back in for any changes to take affect.`}
        />
      )}
    </Form>
  );
}
