import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import {
  createIncident,
  patchIncident,
} from "../../../services/incident-service";
import { Button, Typography } from "@mui/material";
import { getIncidentLookups } from "../../../services/lookup";
import { getAssetLayerById } from "./../../../services/assets";
import { validationService } from "./../../../services/validation";
import { AttachmentViewer } from "./../../attachment-viewer";
import { LynxDialog } from "./../../lynx-dialog";
import "./incident-form.css";
import { getIncidentLocationSection } from "./incident-location";
import { getDepartments } from "services/department-service";
import SingleSelect from "../../form-controls/single-select";
import { useSelector } from "react-redux";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { canSubmitStage } from "services/incident-assignment-service";
import { IncidentStatus, IncidentUserTypes } from "types/enums";
import useAlert from "hooks/useAlert";
import {
  AssetDto,
  AssetLayerDto,
  IncidentDto,
  IncidentParticipantDto,
  LookupDto,
  UserDto,
} from "types";
import IncidentInitialReviewSection from "./incident-initial-review";
import IncidentParticipants from "./incident-participants";
import { RootState } from "types";

interface IncidentFormProps {
  incident?: IncidentDto;
  isExistingIncident: boolean;
  showAttachments: boolean;
  updateIncident: (incident: IncidentDto) => void;
}

interface RouteParams {
  id: string;
}

export interface IncidentFormState {
  assetId?: string;
  assetIdError?: string;
  incidentDateTime?: string;
  incidentDateTimeError?: string;
  expectedTypeId?: string;
  expectedTypeIdError?: string;
  expectedSubTypeId?: string;
  expectedSubTypeIdError?: string;
  expectedSeverityId?: string;
  expectedSeverityIdError?: string;
  title?: string;
  titleError?: string;
  description?: string;
  descriptionError?: string;
  immediateActions?: string;
  immediateActionError?: string;
  contributingFactors?: string;
  contributingFactorsError?: string;
  locationDetails?: string;
  locationDetailsError?: string;
  latitude?: string;
  longitude?: string;
  affectedPartiesError?: string;
  locationError?: string;
  userParticipants: UserDto[];
  userWitnesses?: UserDto[];
  expectedDepartmentId?: string;
  typeId?: string;
  subTypeId?: string;
  severityId?: string;
  investigationRequired?: boolean;
  investigationLeadUserId?: string;
  departmentId?: string;

  immediateActionsError?: string;
}

const IncidentForm: FC<IncidentFormProps> = (props) => {
  const initialForm: IncidentFormState = {
    assetId: "",
    assetIdError: "",
    incidentDateTime: moment().format("YYYY-MM-DD HH:mm"),
    incidentDateTimeError: "",
    expectedTypeId: "",
    expectedTypeIdError: "",
    expectedSubTypeId: "",
    expectedSubTypeIdError: "",
    expectedSeverityId: "",
    expectedSeverityIdError: "",
    title: "",
    titleError: "",
    typeId: "",
    subTypeId: "",
    description: "",
    descriptionError: "",
    immediateActions: "",
    immediateActionError: "",
    contributingFactors: "",
    contributingFactorsError: "",
    locationDetails: "",
    locationDetailsError: "",
    latitude: "",
    longitude: "",
    affectedPartiesError: "",
    locationError: "",
    userParticipants: [],
    userWitnesses: [],
    expectedDepartmentId: "",
    severityId: "",
    investigationRequired: false,
    investigationLeadUserId: "",
    departmentId: "",
  };

  const [formState, setFormState] = useState<IncidentFormState>(
    {} as IncidentFormState
  );
  const [initialFormState, setInitialFormState] = useState<IncidentFormState>(
    {} as IncidentFormState
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showMatrixDialog, setShowMatrixDialog] = useState<boolean>(false);
  const [assetLookups, setAssetLookups] = useState<AssetDto[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [incidentTypes, setIncidentTypes] = useState<LookupDto[]>([]);
  const [incidentSubTypes, setIncidentSubTypes] = useState<LookupDto[]>([]);
  const [incidentServerities, setIncidentSeverities] = useState<LookupDto[]>(
    []
  );
  const [existingIncident, setExistingIncident] = useState<IncidentDto>();
  const [defaultLatitude, setDefaultLatitude] = useState<string>("");
  const [defaultLongitude, setDefaultLongitude] = useState<string>("");
  const [selectedAssetGeojson, setSelectedAssetGeojson] =
    useState<AssetLayerDto>();
  const [locationTouched, setLocationTouched] = useState<boolean>(false);
  const [selectedExpectedSubTypes, setSelectedExpectedSubTypes] = useState<
    LookupDto[]
  >([]);
  const [affectedParties, setAffectedParties] = useState<
    IncidentParticipantDto[]
  >([]);
  const [incidentParticipantTypes, setIncidnetParticipantTypes] = useState<
    any[]
  >([]);
  const [witnesses, setWitnesses] = useState<IncidentParticipantDto[]>([]);
  const [selectedSubTypes, setSelectedSubTypes] = useState<any[]>([]);
  const [incidentSaving, setIncidentSaving] = useState<boolean>(false);
  const [departments, setDepartments] = useState<any[]>([]);
  const { users } = useSelector((state: RootState) => state.lookups);
  const account = useSelector((state: RootState) => state.account);
  const incidentStatus: string = _.toLower(props.incident?.status);

  const isInitiallyLocked: boolean =
    props.isExistingIncident && incidentStatus !== IncidentStatus.PendingReview;

  const [isLocked, setIsLocked] = useState<{
    initialReview: boolean;
    initialReport: boolean;
  }>({
    initialReview: isInitiallyLocked,
    initialReport: isInitiallyLocked,
  });
  const isInitialReportLocked: boolean = isLocked.initialReport;
  const { showAlert } = useAlert();
  const history = useHistory();

  const params = useParams<RouteParams>();

  useEffect(() => {
    if (params.id && props.incident) {
      setExistingIncident(props.incident);
      setLocationTouched(true);
      setFormStateFromIncident(props.incident);
    } else {
      setFormState(initialForm);
    }
    loadLookups();
    loadDepartments();
  }, [props.incident, params.id]);

  useEffect(() => {
    let asset = assetLookups.find(
      (x) => x.id?.toString() === formState.assetId
    );
    if (asset) {
      if (!locationTouched) {
        setDefaultLatitude(asset.centroidLatitude?.toString() || "");
        setDefaultLongitude(asset.centroidLongitude?.toString() || "");
      }
      if (asset.id) {
        getAssetLayerGeojson(asset.id.toString());
      }
    }
  }, [formState.assetId]);

  useEffect(() => {
    if (
      !_.isEmpty(users) &&
      !_.isEmpty(existingIncident) &&
      users &&
      existingIncident.incidentParticipants
    ) {
      let witnessIds = existingIncident.incidentParticipants
        .filter((x) => x.isWitness && x.userId)
        .map((a) => a.userId);
      let witnesses = users.filter((a) => witnessIds.includes(a.id));

      let participantIds = existingIncident.incidentParticipants
        .filter((x) => !x.isWitness && x.userId)
        .map((a) => a.userId);
      let participants = users.filter((a) => participantIds.includes(a.id));

      setFormState((existing) => {
        return {
          ...existing,
          userParticipants: participants,
          userWitnesses: witnesses,
        };
      });
    }
  }, [users, existingIncident]);

  useEffect(() => {
    if (formState.expectedTypeId !== "" && !_.isEmpty(incidentSubTypes)) {
      setSelectedExpectedSubTypes(
        incidentSubTypes.filter(
          (x) => x.parentLookupId == formState.expectedTypeId
        )
      );
    }
    if (formState.expectedTypeId === "") {
      setSelectedExpectedSubTypes([]);
      setFormState((existing) => {
        return { ...existing, expectedSubTypeId: "" };
      });
    }

    if (formState.typeId !== "" && !_.isEmpty(incidentSubTypes)) {
      setSelectedSubTypes(
        incidentSubTypes.filter((x) => x.parentLookupId == formState.typeId)
      );
    }
    if (formState.typeId === "") {
      setSelectedSubTypes([]);
      setFormState((existing) => {
        return { ...existing, subTypeId: "" };
      });
    }
  }, [formState.expectedTypeId, incidentSubTypes, formState.typeId]);

  const loadLookups = () => {
    getIncidentLookups().then((res) => {
      setAssetLookups(JSON.parse(res.data["Assets"]));
      setIncidentTypes(JSON.parse(res.data["IncidentType"]));
      setIncidentSubTypes(JSON.parse(res.data["IncidentSubType"]));
      setIncidentSeverities(JSON.parse(res.data["IncidentSeverity"]));
      setIncidnetParticipantTypes(
        JSON.parse(res.data["IncidentParticipantType"])
      );
      setIsLoading(false);
    });
  };

  const canPerformInitialReview = (): boolean => {
    return (
      !_.isEmpty(existingIncident) &&
      Boolean(account.id) &&
      canSubmitStage(
        existingIncident.incidentUsers,
        account.id,
        IncidentUserTypes.InitialReviewer
      )
    );
  };

  const isExistingIncident = (): boolean => {
    return !!params.id;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newState = { ...formState };
    let { name, value } = e.target;
    if (name === "typeId") {
      newState.subTypeId = "";
    }
    if (name === "expectedTypeId") {
      newState.expectedSubTypeId = "";
    }
    _.set(
      newState,
      name,
      name === "investigationRequired" ? e.target.checked : value
    );

    setFormState(newState);
  };

  const handleLatLongChange = (lat: string, long: string) => {
    setFormState((existingState) => {
      return { ...existingState, latitude: lat, longitude: long };
    });
  };

  const getAssetLayerGeojson = (assetId: string) => {
    getAssetLayerById(assetId).then((res) => {
      if (!_.isEmpty(res.data.geojson)) {
        setSelectedAssetGeojson(JSON.parse(res.data.geojson));
      }
    });
  };

  const hasNoFooter = (): boolean => {
    if (isDisabled() || isLoading) return true;
    return false;
  };

  const setFormStateFromIncident = (incident: IncidentDto) => {
    let newState: IncidentFormState = {
      assetId: incident.assetId?.toString(),
      incidentDateTime: moment(incident.incidentDateTime).format(
        "YYYY-MM-DD HH:mm"
      ),
      expectedTypeId: incident.expectedTypeId?.toString(),
      expectedSubTypeId: incident.expectedSubTypeId?.toString() ?? "",
      expectedSeverityId: incident.expectedSeverityId?.toString(),
      title: incident.title,
      description: incident.description,
      immediateActions: incident.immediateActions,
      contributingFactors: incident.contributingFactors,
      locationDetails: incident.locationDetails,
      latitude: incident.latitude?.toString(),
      longitude: incident.longitude?.toString(),
      typeId: incident.typeId?.toString(),
      subTypeId: incident.subTypeId?.toString(),
      severityId: incident.severityId?.toString(),
      investigationRequired: incident.investigationRequired,
      investigationLeadUserId: incident.investigationLeadUserId?.toString(),
      userParticipants: [],
      userWitnesses: [],
      expectedDepartmentId: incident.expectedDepartmentId?.toString() ?? "",
      departmentId: incident.departmentId?.toString(),
    };
    setAffectedParties(
      incident.incidentParticipants?.filter((x) => !x.userId && !x.isWitness) ||
        []
    );
    setWitnesses(
      incident.incidentParticipants?.filter((x) => !x.userId && x.isWitness) ||
        []
    );
    setDefaultLatitude(incident.latitude?.toString() || "");
    setDefaultLongitude(incident.longitude?.toString() || "");
    getAssetLayerGeojson(incident.assetId?.toString() || "");

    setFormState((existing) => {
      return { ...existing, ...newState };
    });
    setInitialFormState({
      ...newState,
    });
  };

  const saveIncident = (saveAction: string) => {
    if (!validateDataForSave(saveAction)) {
      return;
    }

    let form = validationService.unsetErrors(
      formState,
      "assetIdError",
      "locationDetailsError",
      "incidentDateTimeError",
      "expectedSeverityIdError",
      "expectedTypeIdError",
      "titleError",
      "descriptionError",
      "immediateActionsError",
      "contributingFactorsError",
      "affectedPartiesError",
      "locationError",
      "subTypeIdError",
      "typeIdError"
    );
    let formToSave = _.cloneDeep(form);
    formToSave.incidentParticipants = [...witnesses, ...affectedParties];
    formToSave.userParticipants = formToSave.userParticipants.map(
      (x: any) => x.id
    );
    formToSave.userWitnesses = formToSave.userWitnesses.map((x: any) => x.id);
    formToSave.incidentDateTime =
      formToSave.incidentDateTime === ""
        ? ""
        : moment(formToSave.incidentDateTime).utc().format();
    if (!isExistingIncident()) {
      setIncidentSaving(true);
      createIncident(formToSave, uploadedFiles)
        .then((res) => {
          showAlert("success", "Incident report submitted.");
          setIncidentSaving(false);
          history.push(`/incidents/${res.data.id}`);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
          setIncidentSaving(false);
        });
    } else {
      let dto = {
        incidentParticipants: formToSave.incidentParticipants,
        userWitnesses: formToSave.userWitnesses,
        userParticipants: formToSave.userParticipants,
        expectedDepartmentId: formToSave.expectedDepartmentId,
        departmentId: formToSave.departmentId,
        incidentPatch: [] as jsonpatch.Operation[],
      };
      delete formToSave.userWitnesses;
      delete formToSave.userParticipants;
      delete formToSave.incidentParticipants;
      let diff = jsonpatch.compare(initialFormState, formToSave);
      dto = { ...dto, incidentPatch: diff };
      patchIncident(params.id, dto, saveAction)
        .then((res) => {
          showAlert(
            "success",
            saveAction === "initialreview"
              ? "Initial review submitted."
              : "Incident saved."
          );

          setExistingIncident(res.data);
          setFormStateFromIncident(res.data);
          props.updateIncident(res.data);
          if (
            [
              IncidentStatus.FinalReview,
              IncidentStatus.Investigation,
              IncidentStatus.Closed,
            ].includes(incidentStatus)
          ) {
            resetIsLocked();
          }
        })
        .catch((err) => {});
    }
  };

  const validateDataForSave = (saveAction: string): boolean => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "assetId",
      "assetIdError",
      "Asset"
    );

    validationService.validateRequiredField(
      newState,
      "locationDetails",
      "locationDetailsError",
      "Location Details"
    );

    validationService.validateRequiredField(
      newState,
      "incidentDateTime",
      "incidentDateTimeError",
      "Incident Date Time"
    );

    validationService.validateRequiredField(
      newState,
      "expectedSeverityId",
      "expectedSeverityIdError",
      "Expected Severity"
    );

    validationService.validateRequiredField(
      newState,
      "expectedTypeId",
      "expectedTypeIdError",
      "Incident Type"
    );

    validationService.validateRequiredField(
      newState,
      "title",
      "titleError",
      "Title"
    );
    validationService.validateRequiredField(
      newState,
      "description",
      "descriptionError",
      "Description"
    );

    validationService.validateRequiredField(
      newState,
      "immediateActions",
      "immediateActionsError",
      "Immediate Actions Taken"
    );
    validationService.validateRequiredField(
      newState,
      "contributingFactors",
      "contributingFactorsError",
      "Contributing Factor Details"
    );

    if (_.isEmpty(affectedParties) && _.isEmpty(formState.userParticipants)) {
      newState.affectedPartiesError =
        "At least one affected party is required.";
    } else {
      _.unset(newState, "affectedPartiesError");
    }

    if (!newState.latitude || !newState.longitude) {
      newState.locationError = "A valid location is required.";
    } else {
      _.unset(newState, "locationError");
    }
    let errorFields = [
      "assetIdError",
      "locationDetailsError",
      "incidentDateTimeError",
      "expectedSeverityIdError",
      "expectedTypeIdError",
      "titleError",
      "descriptionError",
      "immediateActionsError",
      "contributingFactorsError",
      "affectedPartiesError",
      "locationError",
    ];

    if (saveAction === "initialreview" || saveAction === "saveinitialreview") {
      validationService.validateRequiredField(
        newState,
        "typeId",
        "typeIdError",
        "Type"
      );

      validationService.validateRequiredField(
        newState,
        "subTypeId",
        "subTypeIdError",
        "Subtype"
      );
      validationService.validateRequiredField(
        newState,
        "severityId",
        "severityIdError",
        "Severity"
      );
      errorFields = [
        ...errorFields,
        "typeIdError",
        "subTypeIdError",
        "severityIdError",
      ];
    }

    isFormValid = !validationService.hasError(newState, ...errorFields);

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const isDisabled = (): boolean => {
    if (!props.isExistingIncident) {
      return false;
    }
    if (incidentStatus === IncidentStatus.PendingReview) {
      return false;
    }
    return true;
  };

  const handleInvestigatorChange = (newValue: any) => {
    setFormState((existing) => {
      return {
        ...existing,
        investigationLeadUserId: newValue.id,
      };
    });
  };

  const getLookupLabels = (value: LookupDto) => {
    return (
      <div>
        {value.code}
        {value.description && (
          <Typography variant="caption" component="span">
            &nbsp;-&nbsp;<i>{value.description}</i>
          </Typography>
        )}
      </div>
    );
  };

  const loadDepartments = () => {
    getDepartments().then((res) => {
      setDepartments(res.data);
    });
  };

  const toggleLock = (name: string) => {
    const newState = { ...isLocked };
    const current = _.get(newState, name);
    _.set(newState, name, !current);
    setIsLocked(newState);
  };

  const resetIsLocked = () => {
    setIsLocked({
      initialReview: isInitiallyLocked,
      initialReport: isInitiallyLocked,
    });
  };

  return (
    <>
      {!_.isEmpty(existingIncident) && users && (
        <Container className="form-container" maxWidth="xl">
          <Dimmer active={isLoading} loader>
            <IncidentInitialReviewSection
              handleInputChange={handleInputChange}
              formState={formState as any}
              incidentTypes={incidentTypes}
              selectedSubTypes={selectedSubTypes}
              incidentServerities={incidentServerities}
              users={users}
              incidentStatus={incidentStatus}
              saveIncident={saveIncident}
              formDisabled={incidentStatus === "closed"}
              handleInvestigatorChange={handleInvestigatorChange}
              incident={existingIncident}
              departments={departments}
              isLocked={isLocked.initialReview}
              toggleLock={toggleLock}
              readonly={!canPerformInitialReview()}
            />
          </Dimmer>
        </Container>
      )}

      <Container className="form-container" maxWidth="xl">
        <Dimmer active={isLoading} loader>
          {getIncidentLocationSection({
            handleInputChange: (e: any) => handleInputChange(e),
            formState: formState,
            assetLookups: assetLookups,
            defaultLatitude: +defaultLatitude,
            defaultLongitude: +defaultLongitude,
            handleLatLongChange: (lat, long) =>
              handleLatLongChange(lat.toString(), long.toString()),
            setLocationTouched: setLocationTouched,
            selectedAssetGeojson: selectedAssetGeojson,
            isDisabled: isDisabled(),
            isExistingIncident: isExistingIncident(),
            isLocked: isInitialReportLocked,
            toggleLock: toggleLock,
          })}
        </Dimmer>
      </Container>
      <Container className="form-container" maxWidth="xl">
        <Dimmer active={isLoading && !_.isEmpty(formState)} loader>
          <Form className="card mb-0">
            <Card.Header>
              <Card.Title>Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Grid.Row>
                <Grid.Col md={6} width={12}>
                  <Form.Group isRequired label="Incident Date Time">
                    <Form.Input
                      disabled={isInitialReportLocked}
                      type="datetime-local"
                      value={formState.incidentDateTime}
                      name="incidentDateTime"
                      onChange={handleInputChange}
                      error={formState.incidentDateTimeError}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={6} width={12}>
                  {formState &&
                    (formState.expectedSeverityId ||
                      formState.expectedSeverityId === "") && (
                      <Form.Group label="Expected Severity" isRequired>
                        <SingleSelect
                          disabled={isInitialReportLocked}
                          onChange={handleInputChange}
                          labelFunction={(value: any) => getLookupLabels(value)}
                          dropdownValues={incidentServerities}
                          label="code"
                          id="id"
                          name="expectedSeverityId"
                          value={formState.expectedSeverityId}
                          error={formState.expectedSeverityIdError}
                        />
                      </Form.Group>
                    )}
                </Grid.Col>
                <Grid.Col md={6} width={12}>
                  {formState &&
                    (formState.expectedTypeId ||
                      formState.expectedTypeId === "") && (
                      <Form.Group label="Expected Type" isRequired>
                        <SingleSelect
                          labelFunction={(value: any) => getLookupLabels(value)}
                          disabled={isInitialReportLocked}
                          onChange={handleInputChange}
                          dropdownValues={incidentTypes}
                          label="code"
                          id="id"
                          error={formState.expectedTypeIdError}
                          name="expectedTypeId"
                          value={formState.expectedTypeId}
                        />
                      </Form.Group>
                    )}
                </Grid.Col>
                <Grid.Col md={6} width={12}>
                  {formState &&
                    (formState.expectedSubTypeId ||
                      formState.expectedSubTypeId === "") && (
                      <Form.Group label="Expected Subtype">
                        <SingleSelect
                          labelFunction={(value: any) => getLookupLabels(value)}
                          disabled={
                            formState.expectedTypeId === "" ||
                            isInitialReportLocked
                          }
                          onChange={handleInputChange}
                          dropdownValues={selectedExpectedSubTypes}
                          label="code"
                          id="id"
                          name="expectedSubTypeId"
                          value={formState.expectedSubTypeId}
                        />
                      </Form.Group>
                    )}
                </Grid.Col>
                <Grid.Col md={6} width={12}>
                  {formState &&
                    (formState.expectedDepartmentId ||
                      formState.expectedDepartmentId === "") && (
                      <Form.Group label={"Expected Department"}>
                        <SingleSelect
                          disabled={isInitialReportLocked}
                          onChange={handleInputChange}
                          dropdownValues={departments}
                          label="name"
                          id="id"
                          name="expectedDepartmentId"
                          value={formState.expectedDepartmentId}
                        />
                      </Form.Group>
                    )}
                </Grid.Col>
                <Grid.Col md={12} width={12}>
                  <Form.Group label="Title" isRequired>
                    <Form.Input
                      type="text"
                      disabled={isInitialReportLocked}
                      name="title"
                      onChange={handleInputChange}
                      value={formState.title}
                      error={formState.titleError}
                    ></Form.Input>
                  </Form.Group>
                </Grid.Col>{" "}
                <Grid.Col md={12} width={12}>
                  <Form.Group label="Description" isRequired>
                    <LynxTextArea
                      autoResize
                      name="description"
                      disabled={isInitialReportLocked}
                      onChange={handleInputChange}
                      value={formState.description}
                      error={formState.descriptionError}
                    ></LynxTextArea>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={12} width={12}>
                  <Form.Group label="Immediate Actions Taken" isRequired>
                    <LynxTextArea
                      autoResize
                      name="immediateActions"
                      disabled={isInitialReportLocked}
                      onChange={handleInputChange}
                      value={formState.immediateActions}
                      error={formState.immediateActionsError}
                    ></LynxTextArea>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={12} width={12}>
                  <Form.Group label="Contributing Factor Details" isRequired>
                    <LynxTextArea
                      autoResize
                      name="contributingFactors"
                      onChange={handleInputChange}
                      disabled={isInitialReportLocked}
                      value={formState.contributingFactors}
                      error={formState.contributingFactorsError}
                    ></LynxTextArea>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Form>
        </Dimmer>
      </Container>
      {users && !_.isEmpty(incidentParticipantTypes) && (
        <IncidentParticipants
          witnesses={witnesses}
          setWitnesses={setWitnesses}
          affectedParties={affectedParties}
          setAffectedParties={setAffectedParties}
          isLoading={isLoading}
          users={users}
          incidentParticipantTypes={incidentParticipantTypes}
          isDisabled={() => isInitialReportLocked}
          handleInputChange={(e: any) => handleInputChange(e)}
          formState={formState as any}
          hasNoFooter={hasNoFooter()}
          hasLock={false}
        />
      )}
      {props.showAttachments && (
        <Container className="form-container" maxWidth="xl">
          <Dimmer active={isLoading} loader>
            <Grid.Col md={12} width={12} className="pl-0 pr-0">
              <AttachmentViewer
                isExistingEntity={isExistingIncident()}
                entityType={"Incident"}
                entityId={existingIncident?.id}
                cardClass="mb-0"
                handleSetUploadedFiles={(files: any[]) => {
                  setUploadedFiles(files);
                }}
                rowHeight={200}
                cols={6}
              />
            </Grid.Col>
          </Dimmer>
        </Container>
      )}

      {(!hasNoFooter() || !isInitialReportLocked) && (
        <Container className="form-container" maxWidth="xl">
          <Form className="card ">
            <Card.Footer>
              {!isExistingIncident() && (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    className="float-right"
                    onClick={() => saveIncident("")}
                  >
                    Submit Incident Report
                  </Button>
                </>
              )}
              {(incidentStatus === IncidentStatus.PendingReview ||
                (isInitiallyLocked && !isInitialReportLocked)) && (
                <Button
                  variant="contained"
                  className="float-right mr-2"
                  onClick={() => saveIncident("")}
                >
                  Save Changes
                </Button>
              )}
            </Card.Footer>
          </Form>
        </Container>
      )}

      {incidentSaving && (
        <LynxDialog
          open={incidentSaving}
          title="Submitting incident. Do not close the window."
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      )}
      {showMatrixDialog && (
        <LynxDialog
          open={showMatrixDialog}
          title="Incident Severity Matrix"
          handleConfirm={() => setShowMatrixDialog(false)}
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <img src="https://i0.wp.com/infosecnirvana.com/wp-content/uploads/2014/03/Picture11.png"></img>
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default IncidentForm;
