import { Close } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack";
import React from "react";

const useAlert = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showAlert = (
    variant: AlertColor,
    message: SnackbarMessage,
    option?: OptionsObject
  ) => {
    enqueueSnackbar(message, {
      ...option,
      anchorOrigin: { horizontal: "center", vertical: "top" },
      autoHideDuration: option?.autoHideDuration || 5000,
      variant: variant,
      action: (snackbarId: any) => (
        <Close
          sx={{ cursor: "pointer" }}
          onClick={() => closeSnackbar(snackbarId)}
        />
      ),
    });
  };

  return { showAlert };
};

export default useAlert;
